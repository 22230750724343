import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'

import amazon_web_services from '../resources/images/logos-clouds/amazon-web-services.svg'
import azure from '../resources/images/logos-clouds/azure.svg'
import google_cloud from '../resources/images/logos-clouds/google-cloud.svg'
import aiven from '../resources/images/Homepage/logos/aiven-logo.svg'
import ibm from '../resources/images/cloud/ibm_cloud.svg'
import confluent_cloud from '../resources/images/cloud/confluent_cloud.svg'

import Layout from '../components/Layout'

import { StaticImage } from 'gatsby-plugin-image'

const clouds = () => {
    const data = useStaticQuery(graphql`
        query CloudPageSEO {
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "CloudPage" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <StaticImage
                className="home-fixed-top"
                src="../resources/images/homepage/hero_lens.svg"
                placeholder="blurred"
                alt="Hero Lenses"
            />
            <section className="mt-5 pt-5 pb-5 ">
                <div className="container-1 pt-5 pb-5">
                    <div className="d-flex flex-wrap  mt-3">
                        <div className="col-md-6 pt-0">
                            <p className="title breadcrumb-text">
                                <a href="/architecture-and-integrations/" className="title_text">
                                    PRODUCT
                                </a>{' '}
                                /<span className="title_text font-weight-bold">CLOUDS</span>
                            </p>
                            <h1>DataOps platform on Cloud</h1>
                            <div className="pr-5">
                                <p className="hero-description-dark pr-5">
                                    Lenses is available for your Cloud setup & Kafka Managed
                                    Services. Are you running on Cloud?
                                </p>
                                <div className="mt-2 roll-button">
                                    <a
                                        className="bg-red text-white "
                                        href="/contact-us/"
                                        role="button"
                                    >
                                        <span data-hover="Talk to our team">Talk to our team</span>
                                    </a>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="se-section">
                <div className="container-1 pt-5 pb-5">
                    <div className="d-flex flex-wrap">
                        <div className="col-md-12">
                            <h2 className="paragraph-title mb-3">Supported Clouds with Kafka</h2>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap">
                        <div className="col-md-3 text-center mobile-margin-top">
                            <a href="/cloud/aws-msk/" id="downloads-cloud-amazon">
                                <div className="shadow bg-white  p-4">
                                    <img
                                        className="cloud-img-100 mx-auto"
                                        src={amazon_web_services}
                                        alt="lenses on amazon"
                                    />
                                    <p className="f-18 mt-3">Amazon</p>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center mobile-margin-top">
                            <a href="/cloud/azure/" id="downloads-cloud-azure">
                                <div className="shadow bg-white  p-4">
                                    <img
                                        className="cloud-img-100 mx-auto"
                                        src={azure}
                                        alt="lenses on azure"
                                    />
                                    <p className="f-18 mt-3">Azure HDInsight</p>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center mobile-margin-top">
                            <a href="/cloud/aiven">
                                <div className="shadow bg-white  p-4">
                                    <img
                                        className="cloud-img-100 mx-auto"
                                        src={aiven}
                                        alt="Aiven"
                                    />
                                    <p className="f-18 mt-3">Aiven</p>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center mobile-margin-top">
                            <a href="/cloud/google-cloud/" id="downloads-cloud-googlecloud">
                                <div className="shadow bg-white  p-4">
                                    <img
                                        className="cloud-img-100 mx-auto"
                                        src={google_cloud}
                                        alt="lenses on google"
                                    />
                                    <p className="f-18 mt-3">Google Cloud</p>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center mobile-margin-top mt-3">
                            <a href="https://docs.lenses.io/4.3/installation/cloud/ibm/">
                                <div className="shadow bg-white  p-4">
                                    <img className="cloud-img-100 mx-auto" src={ibm} alt="IBM" />
                                    <p className="f-18 mt-3">IBM</p>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 text-center mobile-margin-top mt-3">
                            <a href="https://help.lenses.io/quickstart/managed-kafka/confluent-cloud/">
                                <div className="shadow bg-white  p-4">
                                    <img
                                        className="cloud-img-100 mx-auto"
                                        src={confluent_cloud}
                                        alt="Confluent cloud"
                                    />
                                    <p className="f-18 mt-3">Confluent Cloud</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cloud-bg-banner">
                <div className="container-1 align-items-center d-flex flex-wrap h-100 mobile-margin-reset justify-content-between">
                    <div className="col-sm-4 col-12">
                        <div className="mobile-margin-right-0 mobile-padding-reset text-white">
                            <div className="pb-3">
                                <span className="text-white fw-500 text-uppercase d-sm-block d-none">
                                    Starting your Kafka cloud journey?
                                </span>
                            </div>
                            <span>
                                <p className="f-28 lh-30 text-white mb-0">
                                    <strong>The managed Kafka comparison guide</strong>
                                </p>{' '}
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-3 col-9">
                        <div className="roll-button">
                            <a
                                className="bg-white primary-text button-mobile-font-size downloadbutton"
                                href="/cloud/managed-kafka-comparison-guide/"
                            >
                                <span data-hover="Compare now">Compare now</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-1 py-5">
                    <div className="d-flex flex-wrap pb-5 mt-4">
                        <div className="col-md-8">
                            <h2 className="paragraph-title mb-3">
                                Bring Lenses to your cloud infrastructure
                            </h2>
                            <p>
                                Lenses gives you the building blocks to create a DataOps platform
                                over any Kafka distribution even on managed services. Whether
                                on-prem or cloud, Lenses provides a gateway directly to your data
                                via self-serviced SQL and streaming data flows.
                            </p>
                            <p>
                                Trusting your data over cloud services, requires a secure gateway
                                which is properly governed. Lenses treats this as a must have
                                requirement for your data platform.
                            </p>
                            <a className="primary-text" href="/architecture-and-integrations/">
                                Lenses architecture <i className="red-pointer-right"></i>
                            </a>
                        </div>
                        <div className="col-md-4">
                            <StaticImage
                                className="img-fluid mx-auto d-block "
                                src="../resources/images/vectors/undraw_To_the_stars_qhyy.svg"
                                placeholder="tracedSVG"
                                alt="lenses on your cloud infrastructure"
                            />
                        </div>
                    </div>
                </div>
                <hr />
            </section>
        </Layout>
    )
}

export default clouds
